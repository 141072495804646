/* storybook-check-ignore */
import { css, Global } from '@emotion/react';
import { colors } from '@opendoor/bricks/theme/eero';

const assetPrefix = 'https://cdn-cosmos.opendoor.com/fonts';

// TODO: after bold font is QA'd on /homes, add woff2s and re-order font src for faster loading.
const Graphik = {
  regular: css`
    @font-face {
      font-display: swap;
      font-family: 'Graphik';
      src: url('${assetPrefix}/graphik-regular/Graphik-Regular.eot?#iefix')
          format('embedded-opentype'),
        url('${assetPrefix}/graphik-regular/Graphik-Regular.woff') format('woff'),
        url('${assetPrefix}/graphik-regular/Graphik-Regular.ttf') format('truetype'),
        url('${assetPrefix}/graphik-regular/Graphik-Regular.svg#Graphik-Regular') format('svg');
      font-weight: 400;
      font-style: normal;
    }
  `,
  medium: css`
    @font-face {
      font-display: swap;
      font-family: 'Graphik';
      src: url('${assetPrefix}/graphik-medium/Graphik-Medium.eot?#iefix')
          format('embedded-opentype'),
        url('${assetPrefix}/graphik-medium/Graphik-Medium.woff') format('woff'),
        url('${assetPrefix}/graphik-medium/Graphik-Medium.ttf') format('truetype'),
        url('${assetPrefix}/graphik-medium/Graphik-Medium.svg#Graphik-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
  `,
  semibold: css`
    @font-face {
      font-display: swap;
      font-family: 'Graphik';
      src: url('${assetPrefix}/graphik-semibold/Graphik-Semibold.eot?#iefix')
          format('embedded-opentype'),
        url('${assetPrefix}/graphik-semibold/Graphik-Semibold.woff') format('woff'),
        url('${assetPrefix}/graphik-semibold/Graphik-Semibold.ttf') format('truetype'),
        url('${assetPrefix}/graphik-semibold/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }
  `,
  bold: css`
    @font-face {
      font-display: swap;
      font-family: 'Graphik';
      src: url('${assetPrefix}/graphik-bold/Graphik-Bold.woff') format('woff2'),
        url('${assetPrefix}/graphik-bold/Graphik-Bold.woff') format('woff'),
        url('${assetPrefix}/graphik-bold/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('${assetPrefix}/graphik-bold/Graphik-Bold.ttf') format('truetype'),
        url('${assetPrefix}/graphik-bold/Graphik-Bold.svg#Graphik-Bold') format('svg');
      font-weight: 800;
      font-style: normal;
    }
  `,
};

const GlobalCss = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hp-reskin-address-entry-wrapper .cta-input-wrapper {
    border-radius: 20px;
    padding: 6px;
  }

  .hp-reskin-address-entry-wrapper .cta-button {
    border-radius: 14px;
  }

  .hp-reskin-address-entry-wrapper .autocomplete-options.result-list {
    background-color: #f6f5f4;
    border: 1px solid var(--black, #000);
  }

  body {
    text-rendering: optimizeLegibility;
    font: 400 16px/1.7 Graphik, sans-serif;
    color: ${colors.neutrals100};
    margin: 0 auto;
    padding: 0;
    background-color: ${colors.neutrals0};
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  figure {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }

  input,
  button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button {
    background-color: transparent;
    overflow: visible;
  }

  button[data-testid='accordion-button'] {
    border: none;
    background: none;
  }

  a {
    color: ${colors.brand50};
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  h4,
  .h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  h6,
  .h6 {
    font-size: 12px;
  }

  h1 {
    font: 600 46px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }
  h2 {
    font: 600 32px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }
  h3 {
    font: 600 28px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }
  h4 {
    font: 600 24px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }
  h5 {
    font: 600 22px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }
  h6 {
    font: 600 18px/1.45 'Graphik', sans-serif;
    color: #1d2c4c;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 32px;
      line-height: 1.2;
    }
    h2 {
      font-size: 28px;
      line-height: 1.2;
    }
    h3 {
      font-size: 24px;
      line-height: 1.2;
    }
    h4 {
      font-size: 20px;
      line-height: 1.2;
    }
    h5 {
      font-size: 18px;
      line-height: 1.2;
    }
    h6 {
      font-size: 16px;
      line-height: 1.2;
    }
  }

  /* aos */
  [data-aos] {
    opacity: 0;
  }
  [data-aos][data-aos][data-aos-duration='50'],
  body[data-aos-duration='50'] [data-aos] {
    transition-duration: 50ms;
  }
  [data-aos][data-aos][data-aos-delay='50'],
  body[data-aos-delay='50'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='50'].aos-animate,
  body[data-aos-delay='50'] [data-aos].aos-animate {
    transition-delay: 50ms;
  }
  [data-aos][data-aos][data-aos-duration='100'],
  body[data-aos-duration='100'] [data-aos] {
    transition-duration: 0.1s;
  }
  [data-aos][data-aos][data-aos-delay='100'],
  body[data-aos-delay='100'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='100'].aos-animate,
  body[data-aos-delay='100'] [data-aos].aos-animate {
    transition-delay: 0.1s;
  }
  [data-aos][data-aos][data-aos-duration='150'],
  body[data-aos-duration='150'] [data-aos] {
    transition-duration: 0.15s;
  }
  [data-aos][data-aos][data-aos-delay='150'],
  body[data-aos-delay='150'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='150'].aos-animate,
  body[data-aos-delay='150'] [data-aos].aos-animate {
    transition-delay: 0.15s;
  }
  [data-aos][data-aos][data-aos-duration='200'],
  body[data-aos-duration='200'] [data-aos] {
    transition-duration: 0.2s;
  }
  [data-aos][data-aos][data-aos-delay='200'],
  body[data-aos-delay='200'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='200'].aos-animate,
  body[data-aos-delay='200'] [data-aos].aos-animate {
    transition-delay: 0.2s;
  }
  [data-aos][data-aos][data-aos-duration='250'],
  body[data-aos-duration='250'] [data-aos] {
    transition-duration: 0.25s;
  }
  [data-aos][data-aos][data-aos-delay='250'],
  body[data-aos-delay='250'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='250'].aos-animate,
  body[data-aos-delay='250'] [data-aos].aos-animate {
    transition-delay: 0.25s;
  }
  [data-aos][data-aos][data-aos-duration='300'],
  body[data-aos-duration='300'] [data-aos] {
    transition-duration: 0.3s;
  }
  [data-aos][data-aos][data-aos-delay='300'],
  body[data-aos-delay='300'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='300'].aos-animate,
  body[data-aos-delay='300'] [data-aos].aos-animate {
    transition-delay: 0.3s;
  }
  [data-aos][data-aos][data-aos-duration='350'],
  body[data-aos-duration='350'] [data-aos] {
    transition-duration: 0.35s;
  }
  [data-aos][data-aos][data-aos-delay='350'],
  body[data-aos-delay='350'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='350'].aos-animate,
  body[data-aos-delay='350'] [data-aos].aos-animate {
    transition-delay: 0.35s;
  }
  [data-aos][data-aos][data-aos-duration='400'],
  body[data-aos-duration='400'] [data-aos] {
    transition-duration: 0.4s;
  }
  [data-aos][data-aos][data-aos-delay='400'],
  body[data-aos-delay='400'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='400'].aos-animate,
  body[data-aos-delay='400'] [data-aos].aos-animate {
    transition-delay: 0.4s;
  }
  [data-aos][data-aos][data-aos-duration='450'],
  body[data-aos-duration='450'] [data-aos] {
    transition-duration: 0.45s;
  }
  [data-aos][data-aos][data-aos-delay='450'],
  body[data-aos-delay='450'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='450'].aos-animate,
  body[data-aos-delay='450'] [data-aos].aos-animate {
    transition-delay: 0.45s;
  }
  [data-aos][data-aos][data-aos-duration='500'],
  body[data-aos-duration='500'] [data-aos] {
    transition-duration: 0.5s;
  }
  [data-aos][data-aos][data-aos-delay='500'],
  body[data-aos-delay='500'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='500'].aos-animate,
  body[data-aos-delay='500'] [data-aos].aos-animate {
    transition-delay: 0.5s;
  }
  [data-aos][data-aos][data-aos-duration='550'],
  body[data-aos-duration='550'] [data-aos] {
    transition-duration: 0.55s;
  }
  [data-aos][data-aos][data-aos-delay='550'],
  body[data-aos-delay='550'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='550'].aos-animate,
  body[data-aos-delay='550'] [data-aos].aos-animate {
    transition-delay: 0.55s;
  }
  [data-aos][data-aos][data-aos-duration='600'],
  body[data-aos-duration='600'] [data-aos] {
    transition-duration: 0.6s;
  }
  [data-aos][data-aos][data-aos-delay='600'],
  body[data-aos-delay='600'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='600'].aos-animate,
  body[data-aos-delay='600'] [data-aos].aos-animate {
    transition-delay: 0.6s;
  }
  [data-aos][data-aos][data-aos-duration='650'],
  body[data-aos-duration='650'] [data-aos] {
    transition-duration: 0.65s;
  }
  [data-aos][data-aos][data-aos-delay='650'],
  body[data-aos-delay='650'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='650'].aos-animate,
  body[data-aos-delay='650'] [data-aos].aos-animate {
    transition-delay: 0.65s;
  }
  [data-aos][data-aos][data-aos-duration='700'],
  body[data-aos-duration='700'] [data-aos] {
    transition-duration: 0.7s;
  }
  [data-aos][data-aos][data-aos-delay='700'],
  body[data-aos-delay='700'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='700'].aos-animate,
  body[data-aos-delay='700'] [data-aos].aos-animate {
    transition-delay: 0.7s;
  }
  [data-aos][data-aos][data-aos-duration='750'],
  body[data-aos-duration='750'] [data-aos] {
    transition-duration: 0.75s;
  }
  [data-aos][data-aos][data-aos-delay='750'],
  body[data-aos-delay='750'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='750'].aos-animate,
  body[data-aos-delay='750'] [data-aos].aos-animate {
    transition-delay: 0.75s;
  }
  [data-aos][data-aos][data-aos-duration='800'],
  body[data-aos-duration='800'] [data-aos] {
    transition-duration: 0.8s;
  }
  [data-aos][data-aos][data-aos-delay='800'],
  body[data-aos-delay='800'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='800'].aos-animate,
  body[data-aos-delay='800'] [data-aos].aos-animate {
    transition-delay: 0.8s;
  }
  [data-aos][data-aos][data-aos-duration='850'],
  body[data-aos-duration='850'] [data-aos] {
    transition-duration: 0.85s;
  }
  [data-aos][data-aos][data-aos-delay='850'],
  body[data-aos-delay='850'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='850'].aos-animate,
  body[data-aos-delay='850'] [data-aos].aos-animate {
    transition-delay: 0.85s;
  }
  [data-aos][data-aos][data-aos-duration='900'],
  body[data-aos-duration='900'] [data-aos] {
    transition-duration: 0.9s;
  }
  [data-aos][data-aos][data-aos-delay='900'],
  body[data-aos-delay='900'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='900'].aos-animate,
  body[data-aos-delay='900'] [data-aos].aos-animate {
    transition-delay: 0.9s;
  }
  [data-aos][data-aos][data-aos-duration='950'],
  body[data-aos-duration='950'] [data-aos] {
    transition-duration: 0.95s;
  }
  [data-aos][data-aos][data-aos-delay='950'],
  body[data-aos-delay='950'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='950'].aos-animate,
  body[data-aos-delay='950'] [data-aos].aos-animate {
    transition-delay: 0.95s;
  }
  [data-aos][data-aos][data-aos-duration='1000'],
  body[data-aos-duration='1000'] [data-aos] {
    transition-duration: 1s;
  }
  [data-aos][data-aos][data-aos-delay='1000'],
  body[data-aos-delay='1000'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1000'].aos-animate,
  body[data-aos-delay='1000'] [data-aos].aos-animate {
    transition-delay: 1s;
  }
  [data-aos][data-aos][data-aos-duration='1050'],
  body[data-aos-duration='1050'] [data-aos] {
    transition-duration: 1.05s;
  }
  [data-aos][data-aos][data-aos-delay='1050'],
  body[data-aos-delay='1050'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1050'].aos-animate,
  body[data-aos-delay='1050'] [data-aos].aos-animate {
    transition-delay: 1.05s;
  }
  [data-aos][data-aos][data-aos-duration='1100'],
  body[data-aos-duration='1100'] [data-aos] {
    transition-duration: 1.1s;
  }
  [data-aos][data-aos][data-aos-delay='1100'],
  body[data-aos-delay='1100'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1100'].aos-animate,
  body[data-aos-delay='1100'] [data-aos].aos-animate {
    transition-delay: 1.1s;
  }
  [data-aos][data-aos][data-aos-duration='1150'],
  body[data-aos-duration='1150'] [data-aos] {
    transition-duration: 1.15s;
  }
  [data-aos][data-aos][data-aos-delay='1150'],
  body[data-aos-delay='1150'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1150'].aos-animate,
  body[data-aos-delay='1150'] [data-aos].aos-animate {
    transition-delay: 1.15s;
  }
  [data-aos][data-aos][data-aos-duration='1200'],
  body[data-aos-duration='1200'] [data-aos] {
    transition-duration: 1.2s;
  }
  [data-aos][data-aos][data-aos-delay='1200'],
  body[data-aos-delay='1200'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1200'].aos-animate,
  body[data-aos-delay='1200'] [data-aos].aos-animate {
    transition-delay: 1.2s;
  }
  [data-aos][data-aos][data-aos-duration='1250'],
  body[data-aos-duration='1250'] [data-aos] {
    transition-duration: 1.25s;
  }
  [data-aos][data-aos][data-aos-delay='1250'],
  body[data-aos-delay='1250'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1250'].aos-animate,
  body[data-aos-delay='1250'] [data-aos].aos-animate {
    transition-delay: 1.25s;
  }
  [data-aos][data-aos][data-aos-duration='1300'],
  body[data-aos-duration='1300'] [data-aos] {
    transition-duration: 1.3s;
  }
  [data-aos][data-aos][data-aos-delay='1300'],
  body[data-aos-delay='1300'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1300'].aos-animate,
  body[data-aos-delay='1300'] [data-aos].aos-animate {
    transition-delay: 1.3s;
  }
  [data-aos][data-aos][data-aos-duration='1350'],
  body[data-aos-duration='1350'] [data-aos] {
    transition-duration: 1.35s;
  }
  [data-aos][data-aos][data-aos-delay='1350'],
  body[data-aos-delay='1350'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1350'].aos-animate,
  body[data-aos-delay='1350'] [data-aos].aos-animate {
    transition-delay: 1.35s;
  }
  [data-aos][data-aos][data-aos-duration='1400'],
  body[data-aos-duration='1400'] [data-aos] {
    transition-duration: 1.4s;
  }
  [data-aos][data-aos][data-aos-delay='1400'],
  body[data-aos-delay='1400'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1400'].aos-animate,
  body[data-aos-delay='1400'] [data-aos].aos-animate {
    transition-delay: 1.4s;
  }
  [data-aos][data-aos][data-aos-duration='1450'],
  body[data-aos-duration='1450'] [data-aos] {
    transition-duration: 1.45s;
  }
  [data-aos][data-aos][data-aos-delay='1450'],
  body[data-aos-delay='1450'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1450'].aos-animate,
  body[data-aos-delay='1450'] [data-aos].aos-animate {
    transition-delay: 1.45s;
  }
  [data-aos][data-aos][data-aos-duration='1500'],
  body[data-aos-duration='1500'] [data-aos] {
    transition-duration: 1.5s;
  }
  [data-aos][data-aos][data-aos-delay='1500'],
  body[data-aos-delay='1500'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1500'].aos-animate,
  body[data-aos-delay='1500'] [data-aos].aos-animate {
    transition-delay: 1.5s;
  }
  [data-aos][data-aos][data-aos-duration='1550'],
  body[data-aos-duration='1550'] [data-aos] {
    transition-duration: 1.55s;
  }
  [data-aos][data-aos][data-aos-delay='1550'],
  body[data-aos-delay='1550'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1550'].aos-animate,
  body[data-aos-delay='1550'] [data-aos].aos-animate {
    transition-delay: 1.55s;
  }
  [data-aos][data-aos][data-aos-duration='1600'],
  body[data-aos-duration='1600'] [data-aos] {
    transition-duration: 1.6s;
  }
  [data-aos][data-aos][data-aos-delay='1600'],
  body[data-aos-delay='1600'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1600'].aos-animate,
  body[data-aos-delay='1600'] [data-aos].aos-animate {
    transition-delay: 1.6s;
  }
  [data-aos][data-aos][data-aos-duration='1650'],
  body[data-aos-duration='1650'] [data-aos] {
    transition-duration: 1.65s;
  }
  [data-aos][data-aos][data-aos-delay='1650'],
  body[data-aos-delay='1650'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1650'].aos-animate,
  body[data-aos-delay='1650'] [data-aos].aos-animate {
    transition-delay: 1.65s;
  }
  [data-aos][data-aos][data-aos-duration='1700'],
  body[data-aos-duration='1700'] [data-aos] {
    transition-duration: 1.7s;
  }
  [data-aos][data-aos][data-aos-delay='1700'],
  body[data-aos-delay='1700'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1700'].aos-animate,
  body[data-aos-delay='1700'] [data-aos].aos-animate {
    transition-delay: 1.7s;
  }
  [data-aos][data-aos][data-aos-duration='1750'],
  body[data-aos-duration='1750'] [data-aos] {
    transition-duration: 1.75s;
  }
  [data-aos][data-aos][data-aos-delay='1750'],
  body[data-aos-delay='1750'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1750'].aos-animate,
  body[data-aos-delay='1750'] [data-aos].aos-animate {
    transition-delay: 1.75s;
  }
  [data-aos][data-aos][data-aos-duration='1800'],
  body[data-aos-duration='1800'] [data-aos] {
    transition-duration: 1.8s;
  }
  [data-aos][data-aos][data-aos-delay='1800'],
  body[data-aos-delay='1800'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1800'].aos-animate,
  body[data-aos-delay='1800'] [data-aos].aos-animate {
    transition-delay: 1.8s;
  }
  [data-aos][data-aos][data-aos-duration='1850'],
  body[data-aos-duration='1850'] [data-aos] {
    transition-duration: 1.85s;
  }
  [data-aos][data-aos][data-aos-delay='1850'],
  body[data-aos-delay='1850'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1850'].aos-animate,
  body[data-aos-delay='1850'] [data-aos].aos-animate {
    transition-delay: 1.85s;
  }
  [data-aos][data-aos][data-aos-duration='1900'],
  body[data-aos-duration='1900'] [data-aos] {
    transition-duration: 1.9s;
  }
  [data-aos][data-aos][data-aos-delay='1900'],
  body[data-aos-delay='1900'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1900'].aos-animate,
  body[data-aos-delay='1900'] [data-aos].aos-animate {
    transition-delay: 1.9s;
  }
  [data-aos][data-aos][data-aos-duration='1950'],
  body[data-aos-duration='1950'] [data-aos] {
    transition-duration: 1.95s;
  }
  [data-aos][data-aos][data-aos-delay='1950'],
  body[data-aos-delay='1950'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='1950'].aos-animate,
  body[data-aos-delay='1950'] [data-aos].aos-animate {
    transition-delay: 1.95s;
  }
  [data-aos][data-aos][data-aos-duration='2000'],
  body[data-aos-duration='2000'] [data-aos] {
    transition-duration: 2s;
  }
  [data-aos][data-aos][data-aos-delay='2000'],
  body[data-aos-delay='2000'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2000'].aos-animate,
  body[data-aos-delay='2000'] [data-aos].aos-animate {
    transition-delay: 2s;
  }
  [data-aos][data-aos][data-aos-duration='2050'],
  body[data-aos-duration='2050'] [data-aos] {
    transition-duration: 2.05s;
  }
  [data-aos][data-aos][data-aos-delay='2050'],
  body[data-aos-delay='2050'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2050'].aos-animate,
  body[data-aos-delay='2050'] [data-aos].aos-animate {
    transition-delay: 2.05s;
  }
  [data-aos][data-aos][data-aos-duration='2100'],
  body[data-aos-duration='2100'] [data-aos] {
    transition-duration: 2.1s;
  }
  [data-aos][data-aos][data-aos-delay='2100'],
  body[data-aos-delay='2100'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2100'].aos-animate,
  body[data-aos-delay='2100'] [data-aos].aos-animate {
    transition-delay: 2.1s;
  }
  [data-aos][data-aos][data-aos-duration='2150'],
  body[data-aos-duration='2150'] [data-aos] {
    transition-duration: 2.15s;
  }
  [data-aos][data-aos][data-aos-delay='2150'],
  body[data-aos-delay='2150'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2150'].aos-animate,
  body[data-aos-delay='2150'] [data-aos].aos-animate {
    transition-delay: 2.15s;
  }
  [data-aos][data-aos][data-aos-duration='2200'],
  body[data-aos-duration='2200'] [data-aos] {
    transition-duration: 2.2s;
  }
  [data-aos][data-aos][data-aos-delay='2200'],
  body[data-aos-delay='2200'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2200'].aos-animate,
  body[data-aos-delay='2200'] [data-aos].aos-animate {
    transition-delay: 2.2s;
  }
  [data-aos][data-aos][data-aos-duration='2250'],
  body[data-aos-duration='2250'] [data-aos] {
    transition-duration: 2.25s;
  }
  [data-aos][data-aos][data-aos-delay='2250'],
  body[data-aos-delay='2250'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2250'].aos-animate,
  body[data-aos-delay='2250'] [data-aos].aos-animate {
    transition-delay: 2.25s;
  }
  [data-aos][data-aos][data-aos-duration='2300'],
  body[data-aos-duration='2300'] [data-aos] {
    transition-duration: 2.3s;
  }
  [data-aos][data-aos][data-aos-delay='2300'],
  body[data-aos-delay='2300'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2300'].aos-animate,
  body[data-aos-delay='2300'] [data-aos].aos-animate {
    transition-delay: 2.3s;
  }
  [data-aos][data-aos][data-aos-duration='2350'],
  body[data-aos-duration='2350'] [data-aos] {
    transition-duration: 2.35s;
  }
  [data-aos][data-aos][data-aos-delay='2350'],
  body[data-aos-delay='2350'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2350'].aos-animate,
  body[data-aos-delay='2350'] [data-aos].aos-animate {
    transition-delay: 2.35s;
  }
  [data-aos][data-aos][data-aos-duration='2400'],
  body[data-aos-duration='2400'] [data-aos] {
    transition-duration: 2.4s;
  }
  [data-aos][data-aos][data-aos-delay='2400'],
  body[data-aos-delay='2400'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2400'].aos-animate,
  body[data-aos-delay='2400'] [data-aos].aos-animate {
    transition-delay: 2.4s;
  }
  [data-aos][data-aos][data-aos-duration='2450'],
  body[data-aos-duration='2450'] [data-aos] {
    transition-duration: 2.45s;
  }
  [data-aos][data-aos][data-aos-delay='2450'],
  body[data-aos-delay='2450'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2450'].aos-animate,
  body[data-aos-delay='2450'] [data-aos].aos-animate {
    transition-delay: 2.45s;
  }
  [data-aos][data-aos][data-aos-duration='2500'],
  body[data-aos-duration='2500'] [data-aos] {
    transition-duration: 2.5s;
  }
  [data-aos][data-aos][data-aos-delay='2500'],
  body[data-aos-delay='2500'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2500'].aos-animate,
  body[data-aos-delay='2500'] [data-aos].aos-animate {
    transition-delay: 2.5s;
  }
  [data-aos][data-aos][data-aos-duration='2550'],
  body[data-aos-duration='2550'] [data-aos] {
    transition-duration: 2.55s;
  }
  [data-aos][data-aos][data-aos-delay='2550'],
  body[data-aos-delay='2550'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2550'].aos-animate,
  body[data-aos-delay='2550'] [data-aos].aos-animate {
    transition-delay: 2.55s;
  }
  [data-aos][data-aos][data-aos-duration='2600'],
  body[data-aos-duration='2600'] [data-aos] {
    transition-duration: 2.6s;
  }
  [data-aos][data-aos][data-aos-delay='2600'],
  body[data-aos-delay='2600'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2600'].aos-animate,
  body[data-aos-delay='2600'] [data-aos].aos-animate {
    transition-delay: 2.6s;
  }
  [data-aos][data-aos][data-aos-duration='2650'],
  body[data-aos-duration='2650'] [data-aos] {
    transition-duration: 2.65s;
  }
  [data-aos][data-aos][data-aos-delay='2650'],
  body[data-aos-delay='2650'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2650'].aos-animate,
  body[data-aos-delay='2650'] [data-aos].aos-animate {
    transition-delay: 2.65s;
  }
  [data-aos][data-aos][data-aos-duration='2700'],
  body[data-aos-duration='2700'] [data-aos] {
    transition-duration: 2.7s;
  }
  [data-aos][data-aos][data-aos-delay='2700'],
  body[data-aos-delay='2700'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2700'].aos-animate,
  body[data-aos-delay='2700'] [data-aos].aos-animate {
    transition-delay: 2.7s;
  }
  [data-aos][data-aos][data-aos-duration='2750'],
  body[data-aos-duration='2750'] [data-aos] {
    transition-duration: 2.75s;
  }
  [data-aos][data-aos][data-aos-delay='2750'],
  body[data-aos-delay='2750'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2750'].aos-animate,
  body[data-aos-delay='2750'] [data-aos].aos-animate {
    transition-delay: 2.75s;
  }
  [data-aos][data-aos][data-aos-duration='2800'],
  body[data-aos-duration='2800'] [data-aos] {
    transition-duration: 2.8s;
  }
  [data-aos][data-aos][data-aos-delay='2800'],
  body[data-aos-delay='2800'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2800'].aos-animate,
  body[data-aos-delay='2800'] [data-aos].aos-animate {
    transition-delay: 2.8s;
  }
  [data-aos][data-aos][data-aos-duration='2850'],
  body[data-aos-duration='2850'] [data-aos] {
    transition-duration: 2.85s;
  }
  [data-aos][data-aos][data-aos-delay='2850'],
  body[data-aos-delay='2850'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2850'].aos-animate,
  body[data-aos-delay='2850'] [data-aos].aos-animate {
    transition-delay: 2.85s;
  }
  [data-aos][data-aos][data-aos-duration='2900'],
  body[data-aos-duration='2900'] [data-aos] {
    transition-duration: 2.9s;
  }
  [data-aos][data-aos][data-aos-delay='2900'],
  body[data-aos-delay='2900'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2900'].aos-animate,
  body[data-aos-delay='2900'] [data-aos].aos-animate {
    transition-delay: 2.9s;
  }
  [data-aos][data-aos][data-aos-duration='2950'],
  body[data-aos-duration='2950'] [data-aos] {
    transition-duration: 2.95s;
  }
  [data-aos][data-aos][data-aos-delay='2950'],
  body[data-aos-delay='2950'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='2950'].aos-animate,
  body[data-aos-delay='2950'] [data-aos].aos-animate {
    transition-delay: 2.95s;
  }
  [data-aos][data-aos][data-aos-duration='3000'],
  body[data-aos-duration='3000'] [data-aos] {
    transition-duration: 3s;
  }
  [data-aos][data-aos][data-aos-delay='3000'],
  body[data-aos-delay='3000'] [data-aos] {
    transition-delay: 0;
  }
  [data-aos][data-aos][data-aos-delay='3000'].aos-animate,
  body[data-aos-delay='3000'] [data-aos].aos-animate {
    transition-delay: 3s;
  }
  [data-aos][data-aos][data-aos-easing='linear'],
  body[data-aos-easing='linear'] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
  }
  [data-aos][data-aos][data-aos-easing='ease'],
  body[data-aos-easing='ease'] [data-aos] {
    transition-timing-function: ease;
  }
  [data-aos][data-aos][data-aos-easing='ease-in'],
  body[data-aos-easing='ease-in'] [data-aos] {
    transition-timing-function: ease-in;
  }
  [data-aos][data-aos][data-aos-easing='ease-out'],
  body[data-aos-easing='ease-out'] [data-aos] {
    transition-timing-function: ease-out;
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out'],
  body[data-aos-easing='ease-in-out'] [data-aos] {
    transition-timing-function: ease-in-out;
  }
  [data-aos][data-aos][data-aos-easing='ease-in-back'],
  body[data-aos-easing='ease-in-back'] [data-aos] {
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  [data-aos][data-aos][data-aos-easing='ease-out-back'],
  body[data-aos-easing='ease-out-back'] [data-aos] {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out-back'],
  body[data-aos-easing='ease-in-out-back'] [data-aos] {
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-sine'],
  body[data-aos-easing='ease-in-sine'] [data-aos] {
    transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  [data-aos][data-aos][data-aos-easing='ease-out-sine'],
  body[data-aos-easing='ease-out-sine'] [data-aos] {
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out-sine'],
  body[data-aos-easing='ease-in-out-sine'] [data-aos] {
    transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-quad'],
  body[data-aos-easing='ease-in-quad'] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing='ease-out-quad'],
  body[data-aos-easing='ease-out-quad'] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out-quad'],
  body[data-aos-easing='ease-in-out-quad'] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-cubic'],
  body[data-aos-easing='ease-in-cubic'] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing='ease-out-cubic'],
  body[data-aos-easing='ease-out-cubic'] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out-cubic'],
  body[data-aos-easing='ease-in-out-cubic'] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-quart'],
  body[data-aos-easing='ease-in-quart'] [data-aos] {
    transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  [data-aos][data-aos][data-aos-easing='ease-out-quart'],
  body[data-aos-easing='ease-out-quart'] [data-aos] {
    transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  [data-aos][data-aos][data-aos-easing='ease-in-out-quart'],
  body[data-aos-easing='ease-in-out-quart'] [data-aos] {
    transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  [data-aos^='fade'][data-aos^='fade'] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  [data-aos^='fade'][data-aos^='fade'].aos-animate {
    opacity: 1;
    transform: translateZ(0);
  }
  [data-aos='fade-up'] {
    transform: translate3d(0, 100px, 0);
  }
  [data-aos='fade-down'] {
    transform: translate3d(0, -100px, 0);
  }
  [data-aos='fade-right'] {
    transform: translate3d(-100px, 0, 0);
  }
  [data-aos='fade-left'] {
    transform: translate3d(100px, 0, 0);
  }
  [data-aos='fade-up-right'] {
    transform: translate3d(-100px, 100px, 0);
  }
  [data-aos='fade-up-left'] {
    transform: translate3d(100px, 100px, 0);
  }
  [data-aos='fade-down-right'] {
    transform: translate3d(-100px, -100px, 0);
  }
  [data-aos='fade-down-left'] {
    transform: translate3d(100px, -100px, 0);
  }
  [data-aos^='zoom'][data-aos^='zoom'] {
    opacity: 0;
    transition-property: opacity, transform;
  }
  [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
    opacity: 1;
    transform: translateZ(0) scale(1);
  }
  [data-aos='zoom-in'] {
    transform: scale(0.6);
  }
  [data-aos='zoom-in-up'] {
    transform: translate3d(0, 100px, 0) scale(0.6);
  }
  [data-aos='zoom-in-down'] {
    transform: translate3d(0, -100px, 0) scale(0.6);
  }
  [data-aos='zoom-in-right'] {
    transform: translate3d(-100px, 0, 0) scale(0.6);
  }
  [data-aos='zoom-in-left'] {
    transform: translate3d(100px, 0, 0) scale(0.6);
  }
  [data-aos='zoom-out'] {
    transform: scale(1.2);
  }
  [data-aos='zoom-out-up'] {
    transform: translate3d(0, 100px, 0) scale(1.2);
  }
  [data-aos='zoom-out-down'] {
    transform: translate3d(0, -100px, 0) scale(1.2);
  }
  [data-aos='zoom-out-right'] {
    transform: translate3d(-100px, 0, 0) scale(1.2);
  }
  [data-aos='zoom-out-left'] {
    transform: translate3d(100px, 0, 0) scale(1.2);
  }
  [data-aos^='slide'][data-aos^='slide'] {
    transition-property: transform;
  }
  [data-aos^='slide'][data-aos^='slide'].aos-animate {
    transform: translateZ(0);
  }
  [data-aos='slide-up'] {
    transform: translate3d(0, 100%, 0);
  }
  [data-aos='slide-down'] {
    transform: translate3d(0, -100%, 0);
  }
  [data-aos='slide-right'] {
    transform: translate3d(-100%, 0, 0);
  }
  [data-aos='slide-left'] {
    transform: translate3d(100%, 0, 0);
  }
  [data-aos^='flip'][data-aos^='flip'] {
    backface-visibility: hidden;
    transition-property: transform;
  }
  [data-aos='flip-left'] {
    transform: perspective(2500px) rotateY(-100deg);
  }
  [data-aos='flip-left'].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  [data-aos='flip-right'] {
    transform: perspective(2500px) rotateY(100deg);
  }
  [data-aos='flip-right'].aos-animate {
    transform: perspective(2500px) rotateY(0);
  }
  [data-aos='flip-up'] {
    transform: perspective(2500px) rotateX(-100deg);
  }
  [data-aos='flip-up'].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
  [data-aos='flip-down'] {
    transform: perspective(2500px) rotateX(100deg);
  }
  [data-aos='flip-down'].aos-animate {
    transform: perspective(2500px) rotateX(0);
  }
`;

export type IFontWeights = Array<keyof typeof Graphik>;

type IProps = {
  fontWeights: IFontWeights;
  customCss?: string;
};

const GlobalStyles = ({ fontWeights }: IProps) => {
  return <Global styles={css(...fontWeights.map((weight) => Graphik[weight]), GlobalCss)} />;
};

GlobalStyles.defaultProps = {
  fontWeights: ['regular', 'medium', 'semibold', 'bold'],
};

const CustomGlobalStyles = ({ fontWeights, customCss }: IProps) => {
  return <Global styles={css(...fontWeights.map((weight) => Graphik[weight]), customCss)} />;
};

CustomGlobalStyles.defaultProps = {
  fontWeights: ['regular', 'medium', 'semibold', 'bold'],
};

export { CustomGlobalStyles };

export default GlobalStyles;
